<!--
  只有select框
-->
<template>
  <div ref="newSelect" class="SELECT box-show-1">
    <div v-if="listData.length" class="list">
      <div
        :ref="`list_${index}`"
        v-for="(list, index) in listData"
        :key="index"
        class="cont font_zero fontWeight_medium"
        :style="listStyle(index)"
        @click="selectClick(list, index)"
        @mouseenter="list_mouseenter(index)"
        @mouseleave="list_mouseleave(index)"
        v-html="list.value"
      ></div>
    </div>
    <div v-else class="nodata">NO DATA</div>
  </div>
</template>

<script>
export default {
  props: {
    // list数据
    listData: {
      type: Array,
      default: () => [],
    },
    // 当前激活的index
    activeIndex: {
      type: Number,
      default: () => null,
    },
    // hover背景色
    hoverBackColor: {
      type: String,
      default: () => "#2472B3",
    },
    // hover字体色
    hoverColor: {
      type: String,
      default: () => "#ffffff",
    },
  },
  data() {
    return {};
  },
  methods: {
    // style
    listStyle(index) {
      // console.log(this.activeIndex, index)
      let style = {};
      if (this.activeIndex == index) {
        style.backgroundColor = this.hoverBackColor;
        style.color = this.hoverColor;
      }
      // console.log(style)
      return style;
    },
    setPostion(event) {
      // console.log(event)
      let window_height = window.innerHeight;
      let window_width = window.innerWidth;
      let mouse_x = event.clientX;
      let mouse_y = event.clientY;

      let select = this.$refs.newSelect;

      // 下面空间不足
      if (window_height - mouse_y < select.clientHeight + 10) {
        select.style.top = `${mouse_y - 10 - select.clientHeight}px`;
      } else {
        select.style.top = `${mouse_y + 10}px`;
      }

      // 右边空间不足
      if (window_width - mouse_x < select.clientWidth / 2 + 20) {
        select.style.left = `${mouse_x - select.clientWidth - 20}px`;
      } else if (mouse_x < select.clientWidth / 2) {
        // 左边空间不足
        select.style.left = "20px";
      } else {
        select.style.left = `${mouse_x - select.clientWidth / 2}px`;
      }
    },
    // list 鼠标进入
    list_mouseenter(index) {
      let elementList = this.$refs[`list_${index}`];
      if (elementList && elementList.length) {
        elementList[0].style.backgroundColor = this.hoverBackColor;
        elementList[0].style.color = this.hoverColor;
      }
    },
    // list 鼠标离开
    list_mouseleave(index) {
      if (this.activeIndex == index) {
        return false;
      }
      let elementList = this.$refs[`list_${index}`];
      if (elementList && elementList.length) {
        elementList[0].style.backgroundColor = "inherit";
        elementList[0].style.color = "inherit";
      }
    },
    selectClick(list, index) {
      this.$emit("selectClick", list, index);
    },
  },
};
</script>

<style scoped lang="scss">
.SELECT {
  position: fixed;
  border-radius: 5px;
  background-color: #ffffff;
  color: #9e9e9f;
  overflow: hidden;
  z-index: 11;
  top: -1000px;
  .list {
    min-width: 50px;
    .cont {
      text-align: center;
      padding: 8px 10px;
      cursor: pointer;
      border-bottom: 1px solid #f1f1f0;
      // &:hover{
      //   // background-color: #F1F1F0;
      //   background-color: #2472B3;
      //   color: #ffffff;
      // }
      // &.active{
      //   background-color: #2472B3;
      //   color: #ffffff;
      // }
      &:last-child {
        border-bottom: 0px;
      }
    }
  }
  .nodata {
    padding: 10px;
  }
}
</style>